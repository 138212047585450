import React from 'react';
import "../CSS/Testimonial.css";
import NavBar from '../Component/NavBar';
import Bounce from 'react-reveal/Bounce';


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

import Footer from "../Component/Footer";


const Testimonial = () => {
    return (
        <>

            {/* ///////////////////////////////// section 1 ////////////////////////////////// */}

            <section className='Testimonial-Section-1'>
                <NavBar />
                <div className='Testimonial-Banner'>
                    <h1>
                        <Bounce top cascade>
                            Dance is not just a series of movements, it's a way of life.
                        </Bounce>
                    </h1>
                </div>
            </section>

            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <section className='Testimonial-1-con'>
                <div className='container'>
                    <div className='Testimonial-text-con'>
                        <h2>Student Testimonial</h2>
                    </div>
                    <div className='Testimonial-swaiper-con'>
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >


                            <SwiperSlide>
                                <div className='StudentTestimonial-con'>
                                    <div className='StudentTestimonial-Swiper'>
                                        <p className='Student-Test-Para'>
                                            Best place for those who feels dance is not their cup of tea because #s2dc teaching methods are making it very easy for you and enjoy the process of learning
                                            Me and my friend tried this and now we are enjoying in their weekends batch
                                        </p>
                                        <h3>
                                            Chef Ranjeet Gupta
                                        </h3>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className='StudentTestimonial-con'>
                                    <div className='StudentTestimonial-Swiper'>
                                        <p className='Student-Test-Para'>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum est quidem commodi cum consectetur vel voluptas quam illo expedita nobis laudantium minima dolorem, modi velit temporibus eos minus maxime perferendis?
                                        </p>
                                        <h3>
                                            Testimonial Name
                                        </h3>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className='StudentTestimonial-con'>
                                    <div className='StudentTestimonial-Swiper'>
                                        <p className='Student-Test-Para'>
                                            My son loves to dance .He is been here from more then a year .Sir encourag him a lot, the bond between the teacher and student is amazing.sir is there to help,encourage our child to be better, and teach them to be the best. I found the most inspiring, uplifting, and encouraging dance sir Subhash. My son loves to go and more over he enjoys a lot .                                        </p>
                                        <h3>
                                            Parita Patel
                                        </h3>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className='StudentTestimonial-con'>
                                    <div className='StudentTestimonial-Swiper'>
                                        <p className='Student-Test-Para'>
                                            I am very happy to be part of S2DC. Subhash Sir is a very good choreographer and his passion for dance is amazing. Subhash sir and Anuga ma'm are very committed towards teaching Dance. This is the BEST place to learn dance.                                        </p>
                                        <h3>
                                            NEHA N
                                        </h3>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className='StudentTestimonial-con'>
                                    <div className='StudentTestimonial-Swiper'>
                                        <p className='Student-Test-Para'>
                                            Joining Subhash Shukla dance company (S2DC) was the best decision ever. I got to know about this through my friend. The him-self subhash sir and Anuga Ma’am are so humble and down to earth. They treat each one with same dignity and teach each student with lot of determination.  Give equal attention to each one. One thing I must say about Subhash  sir and Anuga ma’am that they are very good skilled dancer but more that they are good human beings, which is very important and good thing about them. Very happy to be a part of #S2DC. I must recommend to everyone.                                        </p>
                                        <h3>
                                            Aditya Andraskar
                                        </h3>
                                    </div>
                                </div>
                            </SwiperSlide>


                            <SwiperSlide>
                                <div className='StudentTestimonial-con'>
                                    <div className='StudentTestimonial-Swiper'>
                                        <p className='Student-Test-Para'>
                                            Subhash and Anuga, apart from being "Great Trainers",
                                            They Both are Very Concerned about their, Trainees and their Families.

                                            They went OUT OF THE WAY, while Choreographing, Dances for my Daughter's Sangeet.

                                            All the while Present there on the Venue, putting Extra Effort on Setting the Venue.

                                            Plus their Team of 8 Dancers were Full of Power.
                                        </p>
                                        <h3>
                                            Prakash Punjabi
                                        </h3>
                                    </div>
                                </div>
                            </SwiperSlide>

                        </Swiper>
                    </div>
                </div>
            </section>


            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <section className='Testimonial-2-con'>
                <div className='container'>
                    <div className='Second-testimonial-2-con'>
                        <div className='Testimonial-text-con'>
                            <h2>Parent Testimonial</h2>
                        </div>
                        <div className='Testimonial-swaiper-con-2'>
                            <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                Subhash helped me with my sangeet performances. He is patient and extremely helpful (especially for people like me who don't know how to dance :p)
                                            </p>
                                            <h3>
                                                Yash Ringshia
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                Great team and Subhash sir is a very good teacher. He teaches gracefully and is very creative in his performances. Highly recommend for classes!                                            </p>
                                            <h3>
                                                Anchal S
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                Best place for beginners who really want to learn dance basics & any another form of dance..
                                            </p>
                                            <h3>
                                                Abhishek Kumar
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                Had great fun learning from Subhash Sir, he made all dance steps look so easy and got me and my friends into perfect coordination, thank you so much!                                            </p>
                                            <h3>
                                                Swara Desai
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                Its an amazing Studio with some really great teachers. I had fun preparing for my besties Sangeet dance ♡                                            </p>
                                            <h3>
                                                Anam Yaligar
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                You guys are excellent choreographers.
                                                Regards
                                                Kirti bhawnani
                                                Kota
                                            </p>
                                            <h3>
                                                kirti bhawnani
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>




            {/* ///////////////////////////////// section 3 ////////////////////////////////// */}

            <section className='Testimonial-2-con'>
                <div className='container'>
                    <div className='Second-testimonial-3-con'>
                        <div className='Testimonial-text-con'>
                            <h2>Client Testimonial</h2>
                        </div>
                        <div className='Testimonial-swaiper-con-2'>
                            <Swiper
                                spaceBetween={30}
                                centeredSlides={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                They truly put their heat and soul into teaching dance and ensure that everything is taken care of for the event.                                            </p>
                                            <h3>
                                                Rochelle D'Souza
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                I have joined this class just three months back to reduce some weight and to get fit really helping lot to me the training                                            </p>
                                            <h3>
                                                Tinku Shaikh
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                Subhash Sir is an amazing dance choreographer....he organised superb dance drama for Annual Day of RNSIS with beautiful concept and unique dances for the kids of all standards ...keep up the good work Sir                                            </p>
                                            <h3>
                                                Ruchita Solanki
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                It is always amazing and fun to work with subhash shukla dance group....😄                                            </p>
                                            <h3>
                                                Mubasshirin Mulla
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                Subhash is gem of Choreographer, lateral thinker, patience levels are remarkable and on the spot thinker - and more importantly if he can make me dance that gets acknowledged in front of 400+ crowd, then clearly he has great competence and capability. Highly recommended!! Bilkul Rocking Dance Coach!!
                                            </p>
                                            <h3>
                                                Vineet Ranjan
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className='StudentTestimonial-con'>
                                        <div className='StudentTestimonial-Swiper'>
                                            <p>
                                                This is the best class as per as my experience, their teaching methods make u more comfortable and confident while learning and they don't teach only choreography the best part this class prepare you to face the world on beats or any song , after completion of training your body will start reacting on music but you have to learn patiently and with the pace                                            </p>
                                            <h3>
                                                Kanthraj Rk raina
                                            </h3>
                                        </div>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />

        </>
    )
}

export default Testimonial