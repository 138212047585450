import Ahaanpandey from "../Images/Alumini/Ahaan pandey.jpg";
import ajitsodhi from "../Images/Alumini/ajit sodhi.webp";
import anjinidhawan from "../Images/Alumini/anjini dhawan.jpg";
import Bhumipednekar from "../Images/Alumini/Bhumi pednekar.jpg";
import Deekshakaushal from "../Images/Alumini/Deeksha kaushal.jpeg";
import Divyanshakaushiq from "../Images/Alumini/Divyansha kaushiq.jpg";
import download from "../Images/Alumini/download (14).jpg";
import Erickapackard from "../Images/Alumini/Ericka packard.jpg";
import gurfatehpirzada from "../Images/Alumini/gurfateh pirzada.jpg";
import Kunjanand from "../Images/Alumini/Kunj anand.webp";
import mehrinepirzada from "../Images/Alumini/mehrine pirzada.jpg";
import Mohitraina from "../Images/Alumini/Mohit raina.jpg";
import Monicabhaduriya from "../Images/Alumini/Monica bhaduriya.jpeg";
import Navneetmalik from "../Images/Alumini/Navneet malik.jpg";
import Nehamalik from "../Images/Alumini/Neha malik.jpeg";
import Palomadhillon from "../Images/Alumini/Paloma dhillon.jpg";
import PerniaQureshi from "../Images/Alumini/Pernia Qureshi.jpg";
import Rachitbahl from "../Images/Alumini/Rachit bahl.jpeg";
import Rohanvinodmehra from "../Images/Alumini/Rohan vinod mehra.jpg";
import Shivambhargava from "../Images/Alumini/Shivam bhargava.jpg";
import shlokapandit from "../Images/Alumini/shloka pandit.jpg";
import Swativatsa from "../Images/Alumini/Swati vatsa.jpeg";
import TjBhanu from "../Images/Alumini/Tj bhanu.jpeg";
import Tonyluke from "../Images/Alumini/Tony luke.jpg";
import Veerpahuriya from "../Images/Alumini/Veer pahuriya.jpg";

const Alumini = [
    {
        AluminiImage: Ahaanpandey,
        AluminiName:"Ahaan pandey",
    },
    {
        AluminiImage: ajitsodhi,
        AluminiName:"ajit sodhi",
    },
    {
        AluminiImage: anjinidhawan,
        AluminiName:"anjini dhawan",
    },
    {
        AluminiImage: Bhumipednekar,
        AluminiName:"Bhumi pednekar",
    },
    {
        AluminiImage: Deekshakaushal,
        AluminiName:"Deeksha kaushal",
    },
    {
        AluminiImage: Divyanshakaushiq,
        AluminiName:"Divyansha kaushiq",
    },
    {
        AluminiImage: download,
        AluminiName:"",
    },
    {
        AluminiImage: Erickapackard,
        AluminiName:"Ericka packard",
    },
    {
        AluminiImage: gurfatehpirzada,
        AluminiName:"Gurfateh Pirzada",
    },
    {
        AluminiImage: Kunjanand,
        AluminiName:"Kunj anand",
    },
    {
        AluminiImage: mehrinepirzada,
        AluminiName:"Mehrine Pirzada",
    },
    {
        AluminiImage: Mohitraina,
        AluminiName:"Mohit Raina",
    },
    {
        AluminiImage: Monicabhaduriya,
        AluminiName:"Monica bhaduriya",
    },
    {
        AluminiImage: Navneetmalik,
        AluminiName:"Navneet malik",
    },
    {
        AluminiImage: Nehamalik,
        AluminiName:"Neha Malik",
    },
    {
        AluminiImage: Palomadhillon,
        AluminiName:"Paloma dhillon",
    },
    {
        AluminiImage: PerniaQureshi,
        AluminiName:"Pernia Qureshi",
    },
    {
        AluminiImage: Rachitbahl,
        AluminiName:"Rachit bahl",
    },
    {
        AluminiImage: Rohanvinodmehra,
        AluminiName:"Rohan vinodmehra",
    },
    {
        AluminiImage: Shivambhargava,
        AluminiName:"Shivam bhargava",
    },
    {
        AluminiImage: shlokapandit,
        AluminiName:"shloka pandit",
    },
    {
        AluminiImage: Swativatsa,
        AluminiName:"Swati vatsa",
    },
    {
        AluminiImage: TjBhanu,
        AluminiName:"Tj Bhanu",
    },
    {
        AluminiImage: Tonyluke,
        AluminiName:"Tony luke",
    },
    {
        AluminiImage: Veerpahuriya,
        AluminiName:"Veer pahuriya",
    },
]

export default Alumini;