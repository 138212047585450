import React from 'react';
import "../CSS/Service.css";
import Bounce from 'react-reveal/Bounce';
import NavBar from "../Component/NavBar";
import CountUp from 'react-countup';
import Accordion from 'react-bootstrap/Accordion';
import Footer from '../Component/Footer';

import serviceimage01 from "../Images/Banner/Home Gallery Image 02.jpg";
import ServiceData from '../Component/ServiceData';
import { Link } from 'react-router-dom';

const Service = () => {
  return (
    <>
      {/* ///////////////////////////////// section 1 ////////////////////////////////// */}

      <section className='Service-Section-1'>
        <NavBar />
        <div className='Service-Banner'>
          <h1>
            <Bounce top cascade>
              Dance is not just a series of movements, it's a way of life.
            </Bounce>
          </h1>
        </div>
      </section>

      {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

      <section className='service-section2'>
        <div className='container'>
          <div className='Service-Section-2-con'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='Service-Image-main-con'>
                  <div className='Service-Image-con'>
                    <img src={serviceimage01} alt='' />
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='Service-Accordion '>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>PRACTICE</Accordion.Header>
                      <Accordion.Body>
                        In the world of dance, practice isn't just a routine; it's your path to excellence. At S2DC, we understand that practice is the heartbeat of every dancer's journey.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>TRAINING</Accordion.Header>
                      <Accordion.Body>
                        Welcome to S2DC Dance Class Training, where we're dedicated to nurturing your dance skills and passion. Our training program is designed to empower dancers of all levels to reach new heights in their craft.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>GROWTH</Accordion.Header>
                      <Accordion.Body>
                        re you ready to experience unparalleled growth in your dance journey? Join us at S2DC Dance Class and embark on a transformational path to becoming the dancer you've always aspired to be.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>IMPROVEMENT</Accordion.Header>
                      <Accordion.Body>
                        Are you ready to take your dance skills to the next level? Join S2DC Dance Classes and embark on a journey of continuous improvement, artistic growth, and endless possibilities.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>

            </div>

          </div>

        </div>

      </section>


      {/* ///////////////////////////////// section 4 ////////////////////////////////// */}
      <section>
        <div className='section-Aboutus-4'>
          <div className='container'>
            <div className="section1-Aboutus-card-con">

              <div className='row'>

                {
                  ServiceData.map((ele) => {
                    const { Slug, ServiceImage, ServiceName } = ele
                    return (
                      <>
                        <div className='col-md-4'>
                          <Link to={`${Slug}`}>
                            <div className='AS4-Classes-Card-body'>
                              <div className='AS4-Classes-Card-Image'>
                                <img src={ServiceImage} alt="" />
                              </div>

                              <div className='AS4-Classes-Card-text'>
                                <h4>{ServiceName}</h4>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////////////// Section 6 ///////////////////// */}

      <section className='About-Us-Section-6'>
        <div className='container'>
          <div className='AboutUs-Section6-Faq'>
            <div className='About-Us-Section-6-Head-Con'>
              <h2>Frequently Asked Questions</h2>
              <div className='About-Us-Section-6-Head-Para'>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vel aperiam est at deserunt fuga minus praesentium distinctio explicabo eum harum.</p>
              </div>
            </div>
            <div className='FAQ-Accordion'>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>I don’t know anything in dance, can I learn dance?</Accordion.Header>
                  <Accordion.Body>
                    Yes you can definitely learn  dance. It is our job to teach you dance, all you need to do is be regular in the classes.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>What do you teach?</Accordion.Header>
                  <Accordion.Body>
                    We have a series of Batches/Courses & Styles like - Aerobics, Stretching, Waves Workouts, Bollyfit, Zumba, Fitness Workout, Dance Exercises, Bollywood, Bolly-Hop, Bollypop, Free Style, Hip Hop, Lyrical, Semi-Classical, Classical-Kathak, (Folk Dance/Regional Styles Like)-Tollywood, Garbha, Dandiya, Ghoomar, Lavni, Gondhal, Koli, Theatrical & Musical/Dance Drama, Special training for Actor’s, Dance training for aspiring trainers/performers. For more details (give the link of dance courses)
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>I want to become a dance teacher at your institute, what do I do?</Accordion.Header>
                  <Accordion.Body>
                    We provide special training for people aspiring to be trainers. For more details <Link></Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>I want to get in shape will dance help me?</Accordion.Header>
                  <Accordion.Body>
                    Dance will make your body flexible. We have special sessions for people who wish to shape up. For more details <Link></Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>How many songs can you teach me ?</Accordion.Header>
                  <Accordion.Body>
                    We can teach you any number of songs, you wish to learn. We teach you to dance in a rhythm, with this method you will be able pick up any dance quickly.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>In how much time will I become a dancer ?</Accordion.Header>
                  <Accordion.Body>
                    The fact that you have visited this website proves that you are already a dancer. Dance is an art form which requires regular training & showcase, the more you train & showcase, the more you will become perfect.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>What is the right age to learn dance ?</Accordion.Header>
                  <Accordion.Body>
                    There is no age to learn dance, it can be learnt at any age. If you wish to learn you can learn.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>I want to rehearse a dance for a competition, can you help me?</Accordion.Header>
                  <Accordion.Body>
                    Sure, we will prepare you for any kind of dance competition just brief us with the competition rules & regulations
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header>I want to learn choreography, how can you help me?</Accordion.Header>
                  <Accordion.Body>
                    Yes we can help you. Call us for a counseling session on <a> 9920480415 </a>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header>I am an actor, how will dance help me?</Accordion.Header>
                  <Accordion.Body>
                    Dance will make your body flexible, thus your body language in acting will become more easy. Besides we have special classes for actors where we  teach you how to dance in a character for details <Link></Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>What is the most important aspect in learning dance ?</Accordion.Header>
                  <Accordion.Body>
                    Rhythm is the most important thing in dance, if you understand rhythm you will be able to do any kind of dance.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>Do you organize dance shows? </Accordion.Header>
                  <Accordion.Body>
                    Yes we organize different kinds of dance shows. For details visit our <Link>photo gallery</Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header>How can I participate in your professional dance shows?</Accordion.Header>
                  <Accordion.Body>
                    Enroll yourself with us & we will call you for an audition. If you are selected you can participate.<a>9920480415</a>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="13">
                  <Accordion.Header>Who can enroll at your dance class?</Accordion.Header>
                  <Accordion.Body>
                    Anybody who wants to learn dance can enroll with us.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="14">
                  <Accordion.Header>My kid is 4years old can he learn dance?</Accordion.Header>
                  <Accordion.Body>
                    Yes.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="15">
                  <Accordion.Header>I am 65yrs old, can I learn dance</Accordion.Header>
                  <Accordion.Body>
                    Yes.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="16">
                  <Accordion.Header>I want to participate in your in house annual dance shows how can this be possible?</Accordion.Header>
                  <Accordion.Body>
                    Enroll with us & we will update you when we start the annual dance rehearsals.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="17">
                  <Accordion.Header>How does every class begin at Subhash Shukla Dance Company (S2DC) ?</Accordion.Header>
                  <Accordion.Body>
                    Every class begins with a warm up session for 20 minutes followed by dance movements.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="18">
                  <Accordion.Header>How is dance related to emotions?</Accordion.Header>
                  <Accordion.Body>
                    Your body speaks your mind, even when you are not talking to anyone your body is constantly giving signals those are your emotions. Dance is a beautiful way to an expression your soul.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="19">
                  <Accordion.Header>What will dance change in me as a person?</Accordion.Header>
                  <Accordion.Body>
                    Dance can give you a stress free mind, a great sense of confidence, a lot of patience, your memory will improve, your body will become flexible For more details
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="20">
                  <Accordion.Header>How can you help me in building a career in dance?</Accordion.Header>
                  <Accordion.Body>
                    We have special training for people who want to make a career in dance wherein we train you & involve you in all the events for a year. At the end of the training ( upon passing an audition ) you will be given a placement in S2DC.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      {/* ///////////////////////// Section 5 ///////////////////// */}

      {/* ///////////////////////// Section 5 ///////////////////// */}


      <section className='AboutUs-Section-5'>
        <div className='container'>
          {/* <div className='AboutUs-Section-5-Heading'>
            <h2>We are happy for our achievement</h2>
          </div> */}
          <div className='row'>
            <div className='col-md-3 col-sm-3'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={4} end={10000} >

                  </CountUp>
                </h2>
                <p>SHOWES</p>
              </div>
            </div>
            <div className='col-md-3 col-sm-3'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={2} end={10000} >

                  </CountUp>
                </h2>
                <p>STUDENTS</p>
              </div>
            </div>
            <div className='col-md-3 col-sm-3'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={2} end={1000} >

                  </CountUp>
                </h2>
                <p>DANCER</p>
              </div>
            </div>
            <div className='col-md-3 col-sm-3'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={2} end={10} >

                  </CountUp>
                </h2>
                <p>TEACHERS</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

    </>
  )
}

export default Service