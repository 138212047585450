import ServiceImage01 from "../Images/Gallery/Events/Events17.JPG";
import ServiceImage02 from "../Images/Banner/HomeGalleryImage06.jpg";
import ServiceImage03 from "../Images/Gallery/Events/Events06.JPG";
import ServiceImage04 from "../Images/Gallery/Events/Events40.JPG";
import ServiceImage05 from "../Images/Gallery/Events/Events25.JPG";
import ServiceImage06 from "../Images/Gallery/Classes/Party Time-01.jpeg";



const ServiceData = [
    {
        Id: 1,
        Slug: "/adults-group",
        ServiceName: "Adults Group",
        ServiceImage: ServiceImage01,
        ServiceDes: "In our adult group dance classes, you'll embark on an exciting journey through a diverse range of dance styles, from contemporary to hip-hop, salsa, ballroom, and jazz. Our classes not only focus on mastering dance techniques but also delve into the cultural and historical influences that have shaped these art forms. You'll develop a strong foundation in posture, rhythm, and coordination while exploring the art of expression through movement. Whether you're a seasoned dancer or a complete beginner, our classes are designed to foster creativity, boost confidence, and provide a fun and supportive community where you can thrive. Join us to embrace the joy of dance, stay fit, and connect with fellow dance enthusiasts!",
        PointsHead: "beginner",
        Points01: "1. Warm Up",
        Points02: "2. Basic Corio Routine",
        Points03: "3. Choreography on music / song",
        Taglines01: "dance style will be covered in this course",
        TagLinesPara: "In this course, we will explore a diverse range of dance styles, covering four Western dance styles and two folk dances. The four Western dance styles we'll delve into include Contemporary, Hip-Hop, Salsa, and Jazz, each offering a unique blend of technique, expression, and contemporary flair. Additionally, we will immerse ourselves in the vibrant traditions of two enchanting folk dances, allowing you to connect with the rich cultural heritage of different regions. Throughout the course, you will gain proficiency in the fundamental movements and techniques of each style, fostering a holistic understanding and appreciation of the art of dance. Join us for an exciting journey that celebrates the fusion of global dance traditions and modern Western influences.",
        TagliensSec01: "Improver",
        TagliensSec01points01: "1. waves and isolation",
        TagliensSec01Para01: "In adult group dance classes, we focus on teaching waves and isolations as fundamental techniques for enhancing fluidity and precision in dance movements. Waves involve creating a seamless, rolling motion through different parts of the body, allowing dancers to produce a visually captivating effect. We teach techniques to initiate and control waves, emphasizing smooth transitions between body segments such as the arms, torso, and hips. Isolations, on the other hand, emphasize precision by isolating specific body parts to move independently while keeping the rest of the body still.",
        TagliensSec01Para011: "This technique helps dancers execute intricate and controlled movements, adding depth and style to their dance repertoire. Our classes provide a supportive and creative environment for adults to refine their wave and isolation skills, ultimately enhancing their overall dance performance.",
        TagliensSec01points02: "2. basic corio routine",
        TagliensSec01Para02: "In an adults' group dance class, we introduce participants to the basics of a choreographed routine, which we refer to as the 'Basic Coreo Routine.' This routine is designed to teach foundational dance steps, movements, and sequences that form the core of various dance styles, such as hip-hop, jazz, or contemporary. Throughout the class, participants will learn and practice essential dance elements, including footwork, body coordination, transitions, and musicality.",
        TagliensSec01Para021: "The Basic Coreo Routine serves as a starting point for dancers to build their skills and confidence, setting a strong foundation for more complex choreographies in the future. Our goal is to provide a fun and engaging learning experience that empowers adults to express themselves through dance while developing their dance vocabulary and performance abilities.",
        TagliensSec01points03: "3. dance styles",
        TagliensSec01Para03: "In an adults' group dance class, we explore various dance styles to cater to diverse interests and preferences. Our classes offer a comprehensive experience where participants can choose a specific dance style or experiment with different ones to broaden their skills and creativity. We teach a range of styles, including but not limited to hip-hop, contemporary, jazz, ballet, salsa, and ballroom.",
        TagliensSec01Para031: "Each style comes with its unique techniques, rhythms, and artistic expressions. Our instructors provide step-by-step guidance, focusing on the foundational moves, posture, and stylistic nuances of each chosen style. Whether you're looking to groove to urban beats, express emotions through contemporary movements, or embrace the grace of classical ballet, our dance classes offer a welcoming and inclusive space for adults to explore and excel in their chosen dance style.",
        TagliensSec02: "Intensive",
        TagliensSec02Para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
        Id: 2,
        Slug: "/kids-group",
        ServiceName: "Kids Group",
        ServiceImage: ServiceImage02,
        ServiceDes: "Our kids' group dance classes offer an exciting journey into the world of dance for young learners. In each session, children will engage in a dynamic mix of warm-up activities to prevent injuries and get their energy flowing, followed by exploration of various dance styles like ballet, hip-hop, jazz, and contemporary, allowing them to discover their preferences and foster creativity. Through choreography practice, improvisation exercises, and teamwork activities, our classes aim to build coordination, self-expression, social skills, and physical fitness. We provide a safe and enjoyable environment for children to develop a love for dance, make friends, and create unforgettable memories through movement and expression. Join us for a dance adventure filled with fun and learning!",
        PointsHead: "beginner",
        Points01: "1. Warm Up",
        Points02: "2. Basic Corio Routine",
        Points03: "3. Choreography on music / song",
        Taglines01: "dance style will be covered in this course",
        TagLinesPara: "In our Kids Group Dance Course, young dancers will have the opportunity to explore a rich variety of dance styles, with a focus on developing their dance skills and appreciation for different cultural forms of expression. Over the course, we will cover six distinct dance styles, including four Western dance styles and two folk dances. The Western dance styles will include ballet, hip-hop, jazz, and contemporary, allowing children to experience a range of contemporary and urban dance forms. Additionally, we will introduce two folk dances from diverse cultures, providing a unique and enriching cultural experience. Our goal is to foster creativity, coordination, and cultural awareness, offering a well-rounded dance education for kids to enjoy and grow as dancers.",
        TagliensSec01: "Improver",
        TagliensSec01Para: "",
        TagliensSec01points01: "1. waves and isolation",
        TagliensSec01Para01: "In our kids' group dance classes, we introduce young dancers to the exciting world of waves and isolations. Waves involve creating a fluid, rolling motion through different parts of the body, allowing kids to produce captivating visual effects. We teach them how to initiate and control waves, emphasizing smooth transitions between their arms, torso, and hips. Isolations, on the other hand, focus on precision by isolating specific body parts to move independently while keeping the rest of the body still. ",
        TagliensSec01Para011: "This technique helps kids execute intricate and controlled movements, adding style and flair to their dance routines. Our classes provide a fun and supportive environment for children to develop these foundational skills, fostering creativity and self-expression while building a strong foundation for their dance journey.",
        TagliensSec01points02: "2. basic corio routine",
        TagliensSec01Para02: "In our kids' group dance classes, we introduce young dancers to the 'Basic Coreo Routine,' a fundamental choreographed routine designed to teach them essential dance steps, movements, and sequences. This routine serves as the building blocks for various dance styles and helps children develop a strong foundation in dance. Throughout the class, kids will learn and practice basic dance elements, including footwork, body coordination, transitions, and musicality. ",
        TagliensSec01Para021: " The Basic Coreo Routine is an exciting and creative way for kids to express themselves through dance while building confidence and skills that they can apply to more advanced choreographies in the future. Our classes provide a supportive and fun environment for children to embrace the joy of dance and develop their dancing abilities.",
        TagliensSec01points03: "3. dance styles",
        TagliensSec01Para03: "our kids' group dance classes, we introduce young dancers to a variety of dance styles to inspire their creativity and passion for movement. Our classes offer a diverse range of dance styles, including ballet, hip-hop, jazz, contemporary, and more. Each style comes with its unique set of movements, rhythms, and artistic expressions. Through our classes, children have the opportunity to explore different styles, discover their preferences, and develop a well-rounded understanding of dance.",
        TagliensSec01Para031: "We provide expert guidance, breaking down the basics of each style and fostering a supportive and inclusive environment where kids can express themselves, build confidence, and have fun while learning the art of dance. Our goal is to inspire a lifelong love for dance in children, allowing them to grow and excel in their chosen dance style or styles.",
        TagliensSec02Para: "In our dance academy, we offer a diverse curriculum that encompasses choreographed routines, essential basics, intermediate improver lessons, engaging dance-related games, and invigorating brain break exercises. Our choreographed routines span various styles and levels of complexity, allowing students to explore and excel in dance. Our basics classes provide a solid foundation for beginners, focusing on posture, alignment, and fundamental movements. Intermediate dancers can refine their skills in improver lessons with more challenging choreography and techniques. We believe in making learning enjoyable, incorporating fun dance-related games to improve coordination and teamwork while offering brain break exercises to refresh students' focus during classes. Our holistic approach ensures that students of all levels experience the joy and growth that dance can bring.",
    },
    {
        Id: 3,
        Slug: "/classified-batch",
        ServiceName: "Classified Batch",
        ServiceImage: ServiceImage03,
        ServiceDes: "In dance classes, a 'classified batch' typically refers to a group or class of students who have been categorized or grouped together based on their skill level, age, or other criteria. These classified batches are designed to ensure that students receive instruction and training that is appropriate for their abilities and needs.",
        pointstitile01: "Warm-Up",
        Points01: ":- Every dance class usually begins with a warm-up session to prepare the body for movement. This may involve stretching, cardio exercises, and mobility drills to prevent injuries and improve flexibility.",
        pointstitile02: "Technique Training ",
        Points02: ":- In a classified batch, students will receive instruction that is tailored to their specific skill level. Beginners may focus on foundational techniques, while more advanced dancers might work on complex movements, steps, or styles.",
        pointstitile03: "Choreography ",
        Points03: ":- Depending on the dance class's goals, students may learn and practice choreographed routines. In a classified batch, the complexity and style of choreography will be appropriate for the skill level of the students.",
        pointstitile04: "Feedback and Corrections ",
        Points04: ":- Instructors in classified batches provide feedback and corrections to help students improve their technique and performance. This may involve one-on-one guidance or corrections given to the group as a whole.",
        pointstitile05: "Dance Styles ",
        Points05: ":- Different dance classes may focus on specific styles such as ballet, hip-hop, jazz, contemporary, or traditional cultural dances. In a classified batch, you'll concentrate on the style or styles that align with your level and interests.",
        pointstitile06: "Progression ",
        Points06: ":- As students gain proficiency, they will progress to more advanced techniques and choreography. Instructors will continually assess and adjust the curriculum to ensure students are challenged appropriately.",
        pointstitile07: "Rehearsals and Performances",
        Points07: ":- In some dance classes, especially for more advanced students, there may be opportunities to rehearse for performances or competitions. These activities can be an exciting part of the learning process.",
    },
    {
        Id: 4,
        Slug: "/ladies-group",
        ServiceName: "Ladies Group",
        ServiceImage: ServiceImage04,
        ServiceDes: "In Ladies Group dance classes, the primary emphasis is on creating a supportive and inclusive environment for female participants to explore various dance styles and enjoy the art of movement. These classes often cater to women of different ages and backgrounds, fostering a sense of unity among participants. Here's a more detailed breakdown of what typically occurs in Ladies Group dance classes.",
    },
    {
        Id: 5,
        Slug: "/bollyfit-group",
        ServiceName: "Bollyfit Group",
        ServiceImage: ServiceImage05,
        ServiceDes: "In a Bollyfit Group dance class, participants engage in a dynamic fusion of Bollywood dance and fitness. They learn Bollywood dance moves, practice choreographed routines set to popular Bollywood songs, and enjoy an energetic cardio workout. These classes focus on building core strength, flexibility, and full-body conditioning while fostering a fun and expressive environment. Bollyfit Group classes offer a vibrant and social experience where participants can connect, share their love for Bollywood dance, and have a great time staying active to the beat of Bollywood music.",
        pointstitile01: "Bollyfit",
        Points01: ":- In Bollyfit dance classes, participants experience a lively fusion of Bollywood dance and fitness. These classes involve learning Bollywood dance moves, mastering choreographed routines set to popular Bollywood songs, and engaging in an energetic cardio workout. Bollyfit classes also promote core strength, flexibility, and overall body conditioning, all while creating a dynamic and expressive atmosphere. Participants can immerse themselves in the joyful world of Bollywood dance, build a sense of community with fellow enthusiasts, and enjoy a fun-filled way to stay active and culturally connected.",
        pointstitile02: "Bollywood ",
        Points02: ":- In Bollywood dance classes, participants embark on an exciting journey through the vibrant and expressive world of Indian cinema dance. These classes typically involve learning a wide range of Bollywood dance styles and movements, from traditional classical dance forms like Kathak and Bharatanatyam to contemporary and fusion styles. Participants practice choreographed routines set to popular Bollywood songs, enhancing their coordination, rhythm, and storytelling skills. Bollywood dance classes offer an enjoyable and culturally rich experience where participants can connect with the colorful and dynamic spirit of Indian cinema while improving their dance techniques and fitness.",
        pointstitile03: "Aerobics ",
        Points03: ":- In aerobics dance classes, participants engage in a dynamic and rhythmic workout that combines dance movements with aerobic exercises. These classes typically involve a variety of dance styles, from hip-hop and salsa to jazz and contemporary, all set to upbeat music. Participants follow choreographed routines that focus on cardiovascular fitness, coordination, and endurance. The energetic dance moves are designed to elevate heart rates, burn calories, and improve overall fitness levels while providing a fun and enjoyable exercise experience. Aerobics dance classes promote a healthy and active lifestyle, making them an excellent choice for those looking to stay fit while having a blast on the dance floor.",
        pointstitile04: "zumba ",
        Points04: ":- In Zumba dance classes, participants embark on an exhilarating fitness journey that combines dance and aerobic elements to the infectious rhythms of Latin and world music. These classes feature a fusion of dance styles like salsa, merengue, cumbia, and reggaeton, all choreographed into energetic routines. Participants follow the instructor's lead, engaging in dynamic movements that enhance cardiovascular endurance, coordination, and flexibility. Zumba classes are known for their vibrant and party-like atmosphere, making them a highly enjoyable way to work up a sweat, burn calories, and improve overall fitness while feeling the rhythm and energy of the music.",
        pointstitile05: "straching ",
        Points05: ":- Stretching in dance classes is a crucial component for dancers to enhance their flexibility, mobility, and overall performance. During these sessions, participants engage in a series of carefully designed stretches and exercises that target specific muscle groups and joints used in dance movements. These stretches not only help improve a dancer's range of motion but also work to prevent injuries by promoting muscular balance and proper alignment. Stretching in dance classes not only aids in achieving better extensions and lines but also promotes relaxation and body awareness, allowing dancers to move with more grace and precision. It is an essential practice for both beginners and experienced dancers to optimize their physical capabilities and dance with fluidity and ease.",
        pointstitile06: "plyometrics ",
        Points06: ":- In plyometrics dance classes, participants engage in high-intensity exercises that focus on explosive and powerful movements. These classes combine dance elements with plyometric drills, such as jumps, hops, and quick directional changes. Plyometrics help dancers enhance their strength, agility, and dynamic coordination, which are essential for executing challenging dance routines. These classes often emphasize building lower body strength, especially in the legs and glutes, to enable dancers to leap, turn, and perform intricate footwork with precision and energy. Plyometrics in dance classes provide a dynamic and challenging workout, helping dancers develop the explosive power and stamina needed to excel in various dance styles.",
        pointstitile07: "rithumic yoga ",
        Points07: ":- In dance classes that incorporate rhythmic yoga, participants are taught a unique blend of yoga and dance movements. These classes focus on the rhythmic synchronization of breath, body, and music. Participants learn traditional yoga poses and stretches while integrating flowing, dance-inspired sequences that emphasize fluidity, balance, and coordination. Rhythmic yoga in dance classes promotes increased flexibility, improved core strength, and heightened body awareness, enabling dancers to move with grace and precision. It offers a holistic approach to physical conditioning, mindfulness, and self-expression, allowing dancers to connect more deeply with their movements and the rhythm of the music, enhancing their overall dance performance and well-being.",
    },
    {
        Id: 6,
        Slug: "/ttp",
        ServiceName: "TTP",
        ServiceImage: ServiceImage06,
        ServiceDes: "A trainers' training program in dance classes is designed to prepare individuals to become skilled dance instructors. These programs typically cover a range of topics, including dance theory, teaching techniques, choreography, music selection, and classroom management. Trainers learn how to effectively communicate dance concepts, provide constructive feedback, and adapt their teaching to different skill levels and age groups. They also gain practical experience in leading warm-ups, drills, and dance routines. Additionally, these programs often emphasize the importance of safety and injury prevention in dance instruction. Overall, a trainers' training program equips individuals with the knowledge and skills needed to guide and inspire dancers while fostering a positive and inclusive learning environment.",
    },
];

export default ServiceData;
