import HomeGalleryImages01 from "../Images/Home Gallery/Home Gallery Image 01.jpg";
import HomeGalleryImages02 from "../Images/Home Gallery/Home Gallery Image 02.jpg";
import HomeGalleryImages03 from "../Images/Home Gallery/Home Gallery Image 03.jpg";
import HomeGalleryImages04 from "../Images/Home Gallery/Home Gallery Image 04.jpg";
import HomeGalleryImages05 from "../Images/Home Gallery/Home Gallery Image 05.jpg";
import HomeGalleryImages06 from "../Images/Home Gallery/HomeGalleryImage06.jpg";
import HomeGalleryImages07 from "../Images/Home Gallery/HomeGalleryImage07.JPG";
export const photos = [
    {
      src: HomeGalleryImages01,
      width: 3,
      height: 2
    },
    {
      src: HomeGalleryImages02,
      width: 1,
      height: 1
    },
    {
      src: HomeGalleryImages03,
      width: 3,
      height: 3
    },
    {
      src: HomeGalleryImages04,
      width: 3,
      height: 4
    },
    {
      src: HomeGalleryImages05,
      width: 3,
      height: 4
    },
    {
      src: HomeGalleryImages06,
      width: 4,
      height: 3
    },
    {
      src: HomeGalleryImages07,
      width: 3,
      height: 4
    },
    {
      src: "https://source.unsplash.com/PpOHJezOalU/800x599",
      width: 4,
      height: 3
    },
    {
      src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
      width: 4,
      height: 3
    },
    {
      src: "https://source.unsplash.com/XiDA78wAZVw/600x799",
      width: 3,
      height: 4
    },
    {
      src: "https://source.unsplash.com/x8xJpClTvR0/800x599",
      width: 4,
      height: 3
    },
    {
      src: "https://source.unsplash.com/qGQNmBE7mYw/800x599",
      width: 4,
      height: 3
    },
    {
      src: "https://source.unsplash.com/NuO6iTBkHxE/800x599",
      width: 4,
      height: 3
    },
    {
      src: "https://source.unsplash.com/pF1ug8ysTtY/600x400",
      width: 4,
      height: 3
    },
    {
      src: "https://source.unsplash.com/A-fubu9QJxE/800x533",
      width: 4,
      height: 3
    },
    {
      src: "https://source.unsplash.com/5P91SF0zNsI/740x494",
      width: 4,
      height: 3
    }
  ];
  