import React, { useState } from 'react';
import './App.css';
import NavBar from './Component/NavBar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Service from './Pages/Service';
import GalleryPage from "./Pages/GalleryPage";
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import VideoGalleryPage from "./Pages/VideoGalleryPage";
import Testimonial from './Pages/Testimonial';
import Team from './Pages/Team';
import Book from './Pages/Book';
import Syllabus from './Pages/Syllabus';
import ServiceInfo from './Pages/ServiceInfo';
import ServiceData from './Component/ServiceData';

function App() {
  const [loading, setLoading] = useState(true)
  const spinner = document.getElementById('spinner');
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false)
    }, 4000)
  }
  return (
    !loading && (
      <div className="App">
        <BrowserRouter>
          {/* <NavBar /> */}
          <Book />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/Service" element={<Service />} />
            <Route path="/Gallery" element={<GalleryPage />} />
            <Route path="/Testimonial" element={<Testimonial />} />
            <Route path='/TeamS2DC' element={<Team />} />
            <Route path='/syllabus' element={<Syllabus />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path='/Service-Info' element={<ServiceInfo />} />

            {
              ServiceData.map((route, index) => (
                <Route
                  key={index}
                  exact
                  path={route.Slug}
                  element={<ServiceInfo />}
                />
              ))
            }

          </Routes>
        </BrowserRouter>
      </div>
    )
  );
}

export default App;
