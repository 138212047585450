import React, { useState, useCallback } from 'react';
import "../CSS/HomePage.css";
import { AiFillYoutube } from 'react-icons/ai';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import NavBar from "../Component/NavBar";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules

import { Autoplay, Pagination, Navigation } from "swiper";
import Footer from '../Component/Footer';

import OurStory from "../Images/Our Story.png";

// Galler Images 

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../Component/Photos";



//////////// ClientLogo01 /////////////

import nexavectorlogo from "../Images/Key Images/nexa-vector-logo.png";
import Bhavancecollage from "../Images/Key Images/Bhavance collage.jpg";
import BombayCambridgelogo from "../Images/Key Images/Bombay-Cambridgelogo.png";
import industrialandcommercialbankofchina from "../Images/Key Images/industrial and commercial bank of china.webp";
import rnshahinternationalschool from "../Images/Key Images/rn shah international school.webp";
import cpgoenkainternationalschool from "../Images/Key Images/cp-logo.svg";
import DeepTalk from "../Images/Key Images/deep talk.jpg";
import Deepakenergy from "../Images/Key Images/deep talk.jpg";
import GMinternationalschool from "../Images/Key Images/download.jpg";
import Floriddepartmenteducation from "../Images/Key Images/Florid-A.png";
import GyanKendraSchool from "../Images/Key Images/gyan-kendra-secondary-school-1495086543-1.jpg";
import SofitalResortAndHotel from "../Images/Key Images/SofitalResortAndHotel.png";
import Panbaiinternationalschool from "../Images/Key Images/Panbaiinternationalschool.png";
import ongc from "../Images/Key Images/ONGC_Logo.svg.png";
import rotary from "../Images/Key Images/rotary-logo-color-2019-simplified.svg";
import AnjumanSchool from "../Images/Key Images/TRE-010443.jpg";


import { BsCalendar } from 'react-icons/bs';

import HomeBlogImage1 from "../Images/Home Blog Image 1.jpg";
import HomeBlogImage2 from "../Images/Home Blog Image 2.jpg";
import HomeBlogImage3 from "../Images/Home Blog Image 3.jpg";


///////////// Video Image ////////////////

import VideoImage01 from "../Images/VideoImage/Video1.webp";
import HomeVideodata from '../Component/HomeVideodata';


const HomePage = () => {

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const firstTenImages = photos.slice(0, 7);


  return (
    <>
      {/* ------------------------------- Section 1 ------------------------------ */}

      <section className='Home-Section-1'>
        <NavBar />
        <div className='Home-Banner-Con'>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className='Home-Banner Homebanner01'>
                {/* <h1>Best Dance Classes in Andheri</h1> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='Home-Banner Homebanner02'>
                {/* <h1>Find Best Car & Limousine</h1> */}
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className='Home-Banner Homebanner03'>
                <h1>Find Best Car & Limousine</h1>  
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </section>

      {/* ////////////////////////////// Home Section 2 //////////////////////////// */}

      <section className='Home-Section-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='Home-Section-2-AboutUs-con'>
                <div className='Home-Section-2-AboutUs'>
                  <img src={OurStory} alt="" />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='Home-Section-2-AboutUs-details-con'>
                <div className='Home-Section-2-AboutUs-details'>
                  <h6>Our Story</h6>

                  {/* <div className='Home-Section-2-AboutUs-details-style'>
                    <h4>S2DC</h4>
                  </div> */}
                  <p>
                    S2DC offers training classes for those who want to dance for fun, as well as those who want to pursue a professional career in dancing. Each class has a structured syllabus in accordance with the pupil's understanding & level of grasping power. Each class begins with a slow body warm-up and rhythmic movement rehearsal and dance conditioning technique to prepare the body for the specific movements intended in the later part of the class. Specific dance movement techniques are thoroughly broken down and introduced to the students in a simple, easy to understand manner, which is then fused together into combinations and choreographies.
                  </p>
                  <p>
                    Extending the learning beyond the classroom are the annual dance shows and exclusive workshops. The annual dance show performances help the students from different age groups to showcase their art and refine their ability to communicate through dance.
                  </p>
                  <p>
                    Class sizes are typically no more than 10 students. Individual attention and one-on-one teaching are some of the best attributes of S2DC.
                  </p>
                  <div className='Home-Section-2-AboutUs-btn'>
                    <button>
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ////////////////////////////// Home Section 3 //////////////////////////// */}

      <section className='Home-Section3'>
        <div className='Home-Section3-Gallery-head'>
          <div className='container'>
            <div className='Home-Section-gallery-Hed'>
              <h4>
                Image Gallery
              </h4>
            </div>
          </div>
        </div>
        <div className='Home-Section3-Gallery'>
          <Gallery photos={firstTenImages} onClick={openLightbox} />
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map(x => ({
                    ...x,
                    srcset: x.srcSet,
                    caption: x.title
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </section>

      {/* ////////////////////////////// Home Section 8 //////////////////////////// */}

      <section className='Home-Section-8'>
        <div className='container'>
          <div className='Home-Section8-gallery-Hed'>
            <h2>
              Video Gallery
            </h2>
          </div>

          <div className='Home-Section-8-Video-gallery'>
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >

              {
                HomeVideodata.map((ele) => {
                  const { videothumbnail, videoLink } = ele
                  return (
                    <>
                      <SwiperSlide>
                        <a href={videoLink} target='blank'>
                          <div className='Home-Page-Video-gallery-con'>
                            <img src={videothumbnail} alt='' />
                            <div className='Home-Page-Video-Icon'>
                              <AiFillYoutube />
                            </div>
                          </div>
                        </a>
                      </SwiperSlide>
                    </>
                  )
                })
              }
            </Swiper>
          </div>
        </div>
      </section>



      {/* ////////////////////////////// Home Section 4 //////////////////////////// */}

      <section class="services mt-0">

        <div class="serviceInner">

          <div class="serviceHead">

            <h3>Services</h3>

          </div>

          <div class="row">

            <div class="col-md-4">

              <div class="seriveCard">

                <div class="serviceCardIcon">

                  <i class="fa fa-music" aria-hidden="true"></i>

                </div>

                <div class="serciceCardText">

                  <h5>CLASSES / WORKSHOP</h5>

                  <p>Musicals Concerts, Theatrical Acts, Dance Reality Shows, Award Shows, Serials,

                    Advertisements,</p>

                </div>

              </div>

            </div>



            <div class="col-md-4">

              <div class="seriveCard">

                <div class="serviceCardIcon">

                  <i class="fa fa-sign-language" aria-hidden="true"></i>

                </div>

                <div class="serciceCardText">

                  <h5>CHOREOGRAPHY</h5>

                  <p>Choreography for Music videos with Celebrities, Dance Music Video Collaboration.</p>

                </div>

              </div>

            </div>



            <div class="col-md-4">

              <div class="seriveCard">

                <div class="serviceCardIcon">

                  <i class="fa fa-american-sign-language-interpreting" aria-hidden="true"></i>

                </div>

                <div class="serciceCardText">

                  <h5>WEDDING</h5>

                  <p>Choreography and Training for Sangeet &amp; Wedding ceremonies</p>

                </div>

              </div>

            </div>



            <div class="col-md-4">

              <div class="seriveCard">

                <div class="serviceCardIcon">

                  <i class="fa fa-sign-language" aria-hidden="true"></i>

                </div>

                <div class="serciceCardText">

                  <h5>CORPORATE EVENTS</h5>

                  <p>Performances for National &amp; International Shows and Corporate Events</p>

                </div>

              </div>

            </div>



            <div class="col-md-4">

              <div class="seriveCard">

                <div class="serviceCardIcon">

                  <i class="fa fa-trophy" aria-hidden="true"></i>

                </div>

                <div class="serciceCardText">

                  <h5>AWARD FUNCTIONS</h5>

                  <p>Performances in renowned award ceremonies at National &amp; International Platforms</p>

                </div>

              </div>

            </div>



            <div class="col-md-4">

              <div class="seriveCard">

                <div class="serviceCardIcon">

                  <i class="fa fa-sign-language" aria-hidden="true"></i>

                </div>

                <div class="serciceCardText">

                  <h5>CELEBRITY JUDGE</h5>

                  <p>Suresh Mukund &amp; Team as Celebrity Judge (for TV Dance Reality Shows/ Schools / Colleges/

                    Dance Events)</p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ////////////////////////////// Home Section 5 //////////////////////////// */}

      <section className='Home-Section-5'>
        <div className='Home-Testimonial-Head'>
          <h5>What Our Client Say</h5>
        </div>
        <div className='container'>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className='HomeSection-testimonial'>
                <div className='Home-Section-Testimonial-con'>
                  <h3>Chef Ranjeet Gupta</h3>
                  <p>Best place for those who feels dance is not their cup of tea because #s2dc teaching methods are making it very easy for you and enjoy the process of learning
                    Me and my friend tried this and now we are enjoying in their weekends batch</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='HomeSection-testimonial'>
                <div className='Home-Section-Testimonial-con'>
                  <h3>Parita Patel</h3>
                  <p>My son loves to dance .He is been here from more then a year .Sir encourag him a lot, the bond between the teacher and student is amazing.sir is there to help,encourage our child to be better, and teach them to be the best. I found the most inspiring, uplifting, and encouraging dance sir Subhash. My son loves to go and more over he enjoys a lot .</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='HomeSection-testimonial'>
                <div className='Home-Section-Testimonial-con'>
                  <h3>Parita Patel</h3>
                  <p>My son loves to dance .He is been here from more then a year .Sir encourag him a lot, the bond between the teacher and student is amazing.sir is there to help,encourage our child to be better, and teach them to be the best. I found the most inspiring, uplifting, and encouraging dance sir Subhash. My son loves to go and more over he enjoys a lot .</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='HomeSection-testimonial'>
                <div className='Home-Section-Testimonial-con'>
                  <h3>NEHA N</h3>
                  <p>I am very happy to be part of S2DC. Subhash Sir is a very good choreographer and his passion for dance is amazing. Subhash sir and Anuga ma'm are very committed towards teaching Dance. This is the BEST place to learn dance.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='HomeSection-testimonial'>
                <div className='Home-Section-Testimonial-con'>
                  <h3>Prakash Punjabi</h3>
                  <p>Joining Subhash Shukla dance company (S2DC) was the best decision ever. I got to know about this through my friend. The him-self subhash sir and Anuga Ma’am are so humble and down to earth. They treat each one with same dignity and teach each student with lot of determination.  Give equal attention to each one. One thing I must say about Subhash  sir and Anuga ma’am that they are very good skilled dancer but more that they are good human beings, which is very important and good thing about them. Very happy to be a part of #S2DC. I must recommend to everyone.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='HomeSection-testimonial'>
                <div className='Home-Section-Testimonial-con'>
                  <h3>Aditya Andraskar</h3>
                  <p>Subhash and Anuga, apart from being "Great Trainers",
                    They Both are Very Concerned about their, Trainees and their Families.

                    They went OUT OF THE WAY, while Choreographing, Dances for my Daughter's Sangeet.

                    All the while Present there on the Venue, putting Extra Effort on Setting the Venue.

                    Plus their Team of 8 Dancers were Full of Power.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='HomeSection-testimonial'>
                <div className='Home-Section-Testimonial-con'>
                  <h3>Yash Ringshia</h3>
                  <p>Subhash helped me with my sangeet performances. He is patient and extremely helpful (especially for people like me who don't know how to dance :p)
                    Thanks for the help and would highly recommend Subhash for dance practices</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='HomeSection-testimonial'>
                <div className='Home-Section-Testimonial-con'>
                  <h3>Anchal S</h3>
                  <p>Great team and Subhash sir is a very good teacher. He teaches gracefully and is very creative in his performances. Highly recommend for classes!</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='HomeSection-testimonial'>
                <div className='Home-Section-Testimonial-con'>
                  <h3>Abhishek Kumar</h3>
                  <p>Best place for beginners who really want to learn dance basics & any another form of dance..
                    Thank you Subhash sir & Anuga ma'am
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='HomeSection-testimonial'>
                <div className='Home-Section-Testimonial-con'>
                  <h3>Swara Desai</h3>
                  <p>Had great fun learning from Subhash Sir, he made all dance steps look so easy and got me and my friends into perfect coordination, thank you so much!</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='HomeSection-testimonial'>
                <div className='Home-Section-Testimonial-con'>
                  <h3>Anam Yaligar</h3>
                  <p>Its an amazing Studio with some really great teachers. I had fun preparing for my besties Sangeet dance ♡</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* ////////////////////////////// Home Section 6 //////////////////////////// */}

      <section className='Home-Section-6'>
        <div className='Home-Section-6-Heading'>
          <h2>
            Our Clients
          </h2>
          <p>
            Brands we've worked with
          </p>
        </div>
        <div className='container'>
          <div className='Home-Section-6-con'>
            <Swiper
              slidesPerView={3}
              spaceBetween={20}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={cpgoenkainternationalschool} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={GMinternationalschool} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={AnjumanSchool} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={Floriddepartmenteducation} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={SofitalResortAndHotel} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={Bhavancecollage} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={GyanKendraSchool} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={Panbaiinternationalschool} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={rnshahinternationalschool} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={nexavectorlogo} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={ongc} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={rotary} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={BombayCambridgelogo} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={industrialandcommercialbankofchina} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={Deepakenergy} alt="" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='Home-Section-Our-Clients'>
                  <div className='HomeSection-Client-Logo'>
                    <img src={DeepTalk} alt="" />
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      {/* ////////////////////////////// Home Section 7 //////////////////////////// */}

      <section className='Home-Section-7'>
        <div className='container'>

          <div className='Home-Section-7-Head-con'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='Home-Section-7-Head-Text'>
                  <p>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque id dolores dignissimos, molestias nam exercitationem voluptates illo praesentium tempore modi ipsam vitae? Odit, ea hic. Eos quaerat hic nisi quisquam!
                  </p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='Home-Section-7-Head-Details'>
                  <p>
                    <span className='blink-soft'>FOLLOW OUR </span> BLOG AND KEEP UP WITH <span className='blink-soft'> THE LATEST NEWS</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='Home-Section-7-Card-con'>
            <div className='row'>


              <div className='col-md-4'>
                <div className='Home-Section-7-Card-main-con'>
                  <div className='Home-Section-7-Card-Image-con'>
                    <img src={HomeBlogImage1} alt="" />
                  </div>
                  <div className='Home-Section-7-Card-Details-con'>

                    <div className='Home-Section-7-Card-Date'>
                      <BsCalendar />
                      <h4>
                        September 20, 2016
                      </h4>
                    </div>
                    <div className='Home-Section-7-Card-Details-text'>
                      <h4>
                        PERFECT IMPERFECT
                      </h4>
                      <p>
                        Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel...
                      </p>
                    </div>
                  </div>
                </div>
              </div>


              <div className='col-md-4'>
                <div className='Home-Section-7-Card-main-con'>
                  <div className='Home-Section-7-Card-Image-con'>
                    <img src={HomeBlogImage2} alt="" />
                  </div>
                  <div className='Home-Section-7-Card-Details-con'>

                    <div className='Home-Section-7-Card-Date'>
                      <BsCalendar />
                      <h4>
                        September 20, 2016
                      </h4>
                    </div>
                    <div className='Home-Section-7-Card-Details-text'>
                      <h4>
                        WILD AT HEART
                      </h4>
                      <p>
                        Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel...
                      </p>
                    </div>
                  </div>
                </div>
              </div>


              <div className='col-md-4'>
                <div className='Home-Section-7-Card-main-con'>
                  <div className='Home-Section-7-Card-Image-con'>
                    <img src={HomeBlogImage3} alt="" />
                  </div>
                  <div className='Home-Section-7-Card-Details-con'>

                    <div className='Home-Section-7-Card-Date'>
                      <BsCalendar />
                      <h4>
                        September 20, 2016
                      </h4>
                    </div>
                    <div className='Home-Section-7-Card-Details-text'>
                      <h4>
                        HOW TO DO CLASSIC
                      </h4>
                      <p>
                        Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      <Footer />
    </>
  )
}

export default HomePage;