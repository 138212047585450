import Image01 from "../Images/D2DC Our Story Image 2.jpg";
import Image02 from "../Images/D2DC Our Story Image 3.jpg";

import ClassesImage01 from "../Images/Gallery/Classes/Suhail Siddhawani (1).jpg";
import ClassesImage02 from "../Images/Gallery/Classes/Suhail Siddhawani (2).jpg";
import ClassesImage03 from "../Images/Gallery/Classes/Suhail Siddhawani (3).jpg";
import ClassesImage04 from "../Images/Gallery/Classes/Party Time-01.jpeg";
import ClassesImage05 from "../Images/Gallery/Classes/Party Time-02.jpeg";

import EventsImage01 from "../Images/Gallery/Events/Events01.JPG";
import EventsImage02 from "../Images/Gallery/Events/Events02.JPG";
import EventsImage04 from "../Images/Gallery/Events/Events04.JPG";
import EventsImage05 from "../Images/Gallery/Events/Events05.JPG";
import EventsImage06 from "../Images/Gallery/Events/Events06.JPG";
import EventsImage07 from "../Images/Gallery/Events/Events07.jpg";
import EventsImage08 from "../Images/Gallery/Events/Events08.jpg";
import EventsImage09 from "../Images/Gallery/Events/Events09.jpg";
import EventsImage10 from "../Images/Gallery/Events/Events10.JPG";
import EventsImage11 from "../Images/Gallery/Events/Events11.JPG";
import EventsImage12 from "../Images/Gallery/Events/Events12.jpeg";
import EventsImage13 from "../Images/Gallery/Events/Events13.jpeg";
import EventsImage14 from "../Images/Gallery/Events/Events14.jpg";
import EventsImage15 from "../Images/Gallery/Events/Events15.JPG";
import EventsImage16 from "../Images/Gallery/Events/Events16.JPG";
import EventsImage17 from "../Images/Gallery/Events/Events17.JPG";
import EventsImage18 from "../Images/Gallery/Events/Events18.jpg";
import EventsImage19 from "../Images/Gallery/Events/Events19.JPG";
import EventsImage20 from "../Images/Gallery/Events/Events20.jpg";
import EventsImage21 from "../Images/Gallery/Events/Events21.jpg";
import EventsImage22 from "../Images/Gallery/Events/Events22.jpg";
import EventsImage23 from "../Images/Gallery/Events/Events23.JPG";
// import EventsImage24 from "../Images/Gallery/Events/Events24.JPG";
import EventsImage25 from "../Images/Gallery/Events/Events25.JPG";
import EventsImage26 from "../Images/Gallery/Events/Events26.JPG";
import EventsImage27 from "../Images/Gallery/Events/Events27.JPG";
import EventsImage28 from "../Images/Gallery/Events/Events28.JPG";
import EventsImage29 from "../Images/Gallery/Events/Events29.jpeg";
import EventsImage30 from "../Images/Gallery/Events/Events30.jpeg";
import EventsImage31 from "../Images/Gallery/Events/Events31.JPG";
import EventsImage32 from "../Images/Gallery/Events/Events32.JPG";
import EventsImage33 from "../Images/Gallery/Events/Events33.JPG";
// import EventsImage34 from "../Images/Gallery/Events/Events34.JPG";
// import EventsImage35 from "../Images/Gallery/Events/Events35.jpeg";
// import EventsImage36 from "../Images/Gallery/Events/Events36.jpeg";
import EventsImage37 from "../Images/Gallery/Events/Events37.jpeg";
import EventsImage38 from "../Images/Gallery/Events/Events38.jpeg";
// import EventsImage39 from "../Images/Gallery/Events/Events39.JPG";
// import EventsImage40 from "../Images/Gallery/Events/Events40.JPG";
import EventsImage41 from "../Images/Gallery/Events/Events41.JPG";
import EventsImage42 from "../Images/Gallery/Events/Events42.JPG";
import EventsImage43 from "../Images/Gallery/Events/Events43.JPG";
// import EventsImage44 from "../Images/Gallery/Events/Events44.JPG";
// import EventsImage45 from "../Images/Gallery/Events/Events45.JPG";
import EventsImage46 from "../Images/Gallery/Events/Events46.JPG";
// import EventsImage47 from "../Images/Gallery/Events/Events47.jpeg";
import EventsImage48 from "../Images/Gallery/Events/Events48.JPG";
// import EventsImage49 from "../Images/Gallery/Events/Events49.JPG";
import EventsImage50 from "../Images/Gallery/Events/Events50.JPG";
import EventsImage51 from "../Images/Gallery/Events/Events51.jpeg";
// import EventsImage52 from "../Images/Gallery/Events/Events52.jpeg";
// import EventsImage53 from "../Images/Gallery/Events/ONGC Annual-01.JPG";
import EventsImage54 from "../Images/Gallery/Events/ONGC Annual-02.jpg";
import EventsImage55 from "../Images/Gallery/Events/ONGC Annual-03.JPG";
import EventsImage56 from "../Images/Gallery/Events/ONGC Annual-07.jpg";
// import EventsImage57 from "../Images/Gallery/Events/ONGC Annual-13.jpg";
// import EventsImage58 from "../Images/Gallery/Events/ONGC Annual-15.jpg";
import EventsImage59 from "../Images/Gallery/Events/ONGC Annual-18.JPG";
import EventsImage60 from "../Images/Gallery/Events/ONGC Annual-20.JPG";


import ShootsImage01 from "../Images/Gallery/Shoots/Shoots01.JPG";
import ShootsImage02 from "../Images/Gallery/Shoots/Shoots02.JPG";
import ShootsImage03 from "../Images/Gallery/Shoots/Shoots03.JPG";
import ShootsImage04 from "../Images/Gallery/Shoots/Shoots04.JPG";
import ShootsImage05 from "../Images/Gallery/Shoots/Shoots05.JPG";
import ShootsImage06 from "../Images/Gallery/Shoots/Shoots06.JPG";
import ShootsImage07 from "../Images/Gallery/Shoots/Shoots07.JPG";
import ShootsImage08 from "../Images/Gallery/Shoots/Shoots08.JPG";
import ShootsImage09 from "../Images/Gallery/Shoots/Shoots09.JPG";
import ShootsImage10 from "../Images/Gallery/Shoots/Shoots10.JPG";
import ShootsImage11 from "../Images/Gallery/Shoots/Shoots11.jpg";
import ShootsImage12 from "../Images/Gallery/Shoots/Shoots12.JPG";
import ShootsImage13 from "../Images/Gallery/Shoots/Shoots13.JPG";
import ShootsImage14 from "../Images/Gallery/Shoots/Shoots14.JPG";
import ShootsImage15 from "../Images/Gallery/Shoots/Shoots15.JPG";
import ShootsImage16 from "../Images/Gallery/Shoots/Shoots16.JPG";
import ShootsImage17 from "../Images/Gallery/Shoots/Shoots17.JPG";
import ShootsImage18 from "../Images/Gallery/Shoots/Shoots18.JPG";
import ShootsImage19 from "../Images/Gallery/Shoots/St.Mathews School-3.jpg";
import ShootsImage20 from "../Images/Gallery/Shoots/Subhash Shukla.jpg";

import AhmadKhan from "../Images/Gallery/Celebrity/Ahmad Khan With Me-01.JPG";
import AnupamShyam from "../Images/Gallery/Celebrity/Anupam Shyam @-S2DA-01.jpeg";
import ArifZakaria from "../Images/Gallery/Celebrity/Arif Zakaria @ S2DA-02.jpeg";
import ArvindThakur from "../Images/Gallery/Celebrity/Arvind Thakur @ S2DA.JPG";
import AyeshaKapur from "../Images/Gallery/Celebrity/Ayesha Kapur @ S2DA-01.JPG";
import BhumiPednekar from "../Images/Gallery/Celebrity/Bhumi Pednekar @ S2DA-01.jpeg";
import GabriellaBernante from "../Images/Gallery/Celebrity/Gabriella Bernante-01.jpg";
import Gandhigiri from "../Images/Gallery/Celebrity/Gandhigiri-02.jpg";
import GaneshAcharya from "../Images/Gallery/Celebrity/Ganesh Acharya @ S2DA-01.jpeg";
import ManojTiwari from "../Images/Gallery/Celebrity/Manoj Tiwari @ S2DA-02.jpg";
import MollyGanguli from "../Images/Gallery/Celebrity/Molly Ganguli @ S2DA-06.jpeg";
import RajendraGupta from "../Images/Gallery/Celebrity/Rajendra Gupta @ S2DA.jpg";
import RajpalYadav from "../Images/Gallery/Celebrity/Rajpal Yadav @ S2DA.JPG";
import RohanVMehra from "../Images/Gallery/Celebrity/Rohan V. Mehra-01.jpg";
import SahilaChaddha from "../Images/Gallery/Celebrity/Sahila Chaddha @ S2DA.jpeg";
import ShanooSharma from "../Images/Gallery/Celebrity/Shanoo Sharma @-S2DA.jpeg";
import ShlokaPandit from "../Images/Gallery/Celebrity/Shloka Pandit @ S2DA-01.jpeg";
import SmitaJaykar from "../Images/Gallery/Celebrity/Smita Jaykar @ S2DA-01.jpeg";
import UshaUthup from "../Images/Gallery/Celebrity/Usha Uthup @ S2DA.jpg";
import VaibhaviMerchant from "../Images/Gallery/Celebrity/Vaibhavi Merchant @ S2DA.jpeg";
import VikramGokhle from "../Images/Gallery/Celebrity/Vikram Gokhle @ S2DA.jpg";
import WaarisSet from "../Images/Gallery/Celebrity/Waaris Set.jpeg";



const GalleryData = [
    {
        id: 1,
        Category: "Classes",
        EImage: ClassesImage01,
    },
    {
        id: 2,
        Category: "Classes",
        EImage: ClassesImage02,
    },
    {
        id: 3,
        Category: "Classes",
        EImage: ClassesImage03,
    },
    {
        id: 3,
        Category: "Classes",
        EImage: ClassesImage04,
    },
    {
        id: 3,
        Category: "Classes",
        EImage: ClassesImage05,
    },
    {
        id: 4,
        Category: "Rehearsal",
        EImage: Image02,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage01,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage02,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage04,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage05,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage06,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage07,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage08,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage09,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage10,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage11,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage12,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage13,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage14,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage15,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage16,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage17,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage18,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage19,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage20,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage21,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage22,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage23,
    },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage24,
    // },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage25,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage26,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage27,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage28,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage29,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage30,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage31,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage32,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage33,
    },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage34,
    // },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage35,
    // },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage36,
    // },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage37,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage38,
    },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage39,
    // },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage41,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage42,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage43,
    },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage44,
    // },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage45,
    // },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage46,
    },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage47,
    // },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage48,
    },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage49,
    // },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage50,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage51,
    },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage52,
    // },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage53,
    // },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage54,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage55,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage56,
    },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage57,
    // },
    // {
    //     id: 2,
    //     Category: "Events",
    //     EImage: EventsImage58,
    // },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage59,
    },
    {
        id: 2,
        Category: "Events",
        EImage: EventsImage60,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage01,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage02,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage03,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage04,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage05,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage06,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage07,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage08,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage09,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage10,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage11,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage12,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage13,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage14,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage15,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage16,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage17,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage18,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage19,
    },
    {
        id: 4,
        Category: "shoots",
        EImage: ShootsImage20,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: AhmadKhan,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: AnupamShyam,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: ArifZakaria,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: ArvindThakur,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: AyeshaKapur,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: BhumiPednekar,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: GabriellaBernante,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: Gandhigiri,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: GaneshAcharya,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: ManojTiwari,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: MollyGanguli,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: RajendraGupta,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: RajpalYadav,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: RohanVMehra,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: SahilaChaddha,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: ShanooSharma,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: ShlokaPandit,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: SmitaJaykar,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: UshaUthup,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: VaibhaviMerchant,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: VikramGokhle,
    },
    {
        id: 4,
        Category: "Celebrity",
        EImage: WaarisSet,
    },
];

export default GalleryData;
