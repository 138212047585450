import TaaraDiljitDosanjh from "../Images/video thumbnail/5 Taara - Diljit Dosanjh.webp";
import GallanGoodiyaan from "../Images/video thumbnail/Gallan Goodiyaan.webp";
import GanDeva from "../Images/video thumbnail/Gan Deva.webp";
import HumariAtariya from "../Images/video thumbnail/Humari Atariya.webp";
import independenceday from "../Images/video thumbnail/independenceday.webp";
import JhoomeJoPathaan from "../Images/video thumbnail/Jhoome Jo Pathaan.webp";
import JollyGoodFellow from "../Images/video thumbnail/Jolly Good Fellow.webp";
import kisikabhaikisikijaan from "../Images/video thumbnail/kisikabhaikisikijaan.webp";
import LOVER from "../Images/video thumbnail/LOVER.webp";
import Matargashti from "../Images/video thumbnail/Matargashti.webp";
import Sakhiyan2 from "../Images/video thumbnail/Sakhiyan2.0.webp";
import Yentamma from "../Images/video thumbnail/Yentamma.webp";


const HomeVideodata = [
    {
        videothumbnail:TaaraDiljitDosanjh,
        videoLink:"https://www.youtube.com/watch?v=SLr7r9ktIBk",
    },
    {
        videothumbnail:GallanGoodiyaan,
        videoLink:"https://www.youtube.com/watch?v=VMQ0oyDYhac",
    },
    {
        videothumbnail:GanDeva,
        videoLink:"https://www.youtube.com/watch?v=SNe2_1bihfU",
    },
    {
        videothumbnail:HumariAtariya,
        videoLink:"https://www.youtube.com/watch?v=r3hY1-qqQfw",
    },
    {
        videothumbnail:independenceday,
        videoLink:"https://www.youtube.com/watch?v=dDlQolJ0fcY",
    },
    {
        videothumbnail:JhoomeJoPathaan,
        videoLink:"https://www.youtube.com/watch?v=tIXqnA1RYq0",
    },
    {
        videothumbnail:JollyGoodFellow,
        videoLink:"https://www.youtube.com/watch?v=k6yLxlCwlSE",
    },
    {
        videothumbnail:kisikabhaikisikijaan,
        videoLink:"https://www.youtube.com/watch?v=QBnJsjdNO0U",
    },
    {
        videothumbnail:LOVER,
        videoLink:"https://www.youtube.com/watch?v=4drYlIgxrvg",
    },
    {
        videothumbnail:Matargashti,
        videoLink:"https://www.youtube.com/watch?v=gegqIYXCRvA&t=1s",
    },
    {
        videothumbnail:Sakhiyan2,
        videoLink:"https://www.youtube.com/watch?v=klw7QSuy9lA",
    },
    {
        videothumbnail:Yentamma,
        videoLink:"https://www.youtube.com/watch?v=2Ezcp9LkNA4",
    },

];

export default HomeVideodata;
