import React from 'react';
import "../CSS/Team.css";
import Bounce from 'react-reveal/Bounce';
import NavBar from '../Component/NavBar';
import Footer from "../Component/Footer";

import AboutFImage01 from "../Images/About Image 01.png";
import AboutFImage02 from "../Images/About Image 02.png";
import AboutImage from "../Images/sir.JPG"

import Ahaanpandey from "../Images/Alumini/Ahaan pandey.jpg";
import ajitsodhi from "../Images/Alumini/ajit sodhi.webp";
import anjinidhawan from "../Images/Alumini/anjini dhawan.jpg";
import Bhumipednekar from "../Images/Alumini/Bhumi pednekar.jpg";
import Deekshakaushal from "../Images/Alumini/Deeksha kaushal.jpeg";
import Divyanshakaushiq from "../Images/Alumini/Divyansha kaushiq.jpg";
import download from "../Images/Alumini/download (14).jpg";
import Erickapackard from "../Images/Alumini/Ericka packard.jpg";
import gurfatehpirzada from "../Images/Alumini/gurfateh pirzada.jpg";
import Kunjanand from "../Images/Alumini/Kunj anand.webp";
import mehrinepirzada from "../Images/Alumini/mehrine pirzada.jpg";
import Mohitraina from "../Images/Alumini/Mohit raina.jpg";
import Monicabhaduriya from "../Images/Alumini/Monica bhaduriya.jpeg";
import Navneetmalik from "../Images/Alumini/Navneet malik.jpg";
import Nehamalik from "../Images/Alumini/Neha malik.jpeg";
import Palomadhillon from "../Images/Alumini/Paloma dhillon.jpg";
import PerniaQureshi from "../Images/Alumini/Pernia Qureshi.jpg";
import Rachitbahl from "../Images/Alumini/Rachit bahl.jpeg";
import Rohanvinodmehra from "../Images/Alumini/Rohan vinod mehra.jpg";
import Shivambhargava from "../Images/Alumini/Shivam bhargava.jpg";
import shlokapandit from "../Images/Alumini/shloka pandit.jpg";
import Swativatsa from "../Images/Alumini/Swati vatsa.jpeg";
import TjBhanu from "../Images/Alumini/Tj bhanu.jpeg";
import Tonyluke from "../Images/Alumini/Tony luke.jpg";
import Veerpahuriya from "../Images/Alumini/Veer pahuriya.jpg";
import Alumini from '../Component/Alumini';

const Team = () => {
    return (
        <>

            {/* ///////////////////////////////// section 1 ////////////////////////////////// */}

            <section className='Team-Section-1'>
                <NavBar />
                <div className='Team-Banner'>
                    <h1>
                        <Bounce top cascade>
                            Dance is not just a series of movements, it's a way of life.
                        </Bounce>
                    </h1>
                </div>
            </section>

            {/* ///////////////////////////////// section 3 ////////////////////////////////// */}

            <section className='Team-Section-3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='Team-Section-3-Image-Con'>
                                <div className='Team-Section-3-Image'>
                                    <img src={AboutImage} />
                                </div>
                                <div className='Team-Section-3-Image1'>
                                    <img src={AboutFImage01} />
                                </div>
                                <div className='Team-Section-3-Image2'>
                                    <img src={AboutFImage02} />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='Team-Section-3-Details-Con'>
                                <div className='Team-Section-3-Details'>
                                    <h6>About Founder</h6>
                                    <p>Subhash Shukla Dance Company (S2DC) an intensive training ground aimed at creating well rounded individuals and all round professionals in the field of Dance wherein teachers are experienced professionals. Subhash Shukla Dance Company (S2DC)  prefers to work with small classes in order for pupils to receive the highest quality dance tuition with more individual attention.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <section className='Team-Section2'>
                <div class="container">
                    <div className='Team_Section2-Container'>
                        <div className='Team-Section-2-head'>
                            <h2>Creative Director</h2>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1595211877493-41a4e5f236b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=716&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Cleo McDowell <br /><span>Senior Designer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1614317226858-a2bcad5498c0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Sheree Sampson <br /><span>Senior Developer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1606596556957-f6566cc865a9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=666&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Amani Nation <br /><span>Software Engineer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1609436132311-e4b0c9370469?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Andrea Styles <br /><span>Senior Web Developer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <section className='Team-Section2'>
                <div class="container">
                    <div className='Team_Section2-Container'>
                        <div className='Team-Section-2-head'>
                            <h2>Choreographer</h2>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1595211877493-41a4e5f236b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=716&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Cleo McDowell <br /><span>Senior Designer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1614317226858-a2bcad5498c0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Sheree Sampson <br /><span>Senior Developer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1606596556957-f6566cc865a9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=666&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Amani Nation <br /><span>Software Engineer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1609436132311-e4b0c9370469?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Andrea Styles <br /><span>Senior Web Developer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <section className='Team-Section2'>
                <div class="container">
                    <div className='Team_Section2-Container'>
                        <div className='Team-Section-2-head'>
                            <h2>Instructor</h2>
                        </div>
                        <div className='row'>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1595211877493-41a4e5f236b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=716&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Cleo McDowell <br /><span>Senior Designer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1614317226858-a2bcad5498c0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Sheree Sampson <br /><span>Senior Developer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1606596556957-f6566cc865a9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=666&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Amani Nation <br /><span>Software Engineer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                <div class="cards">
                                    <div class="imgBx">
                                        <img
                                            src="https://images.unsplash.com/photo-1609436132311-e4b0c9370469?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=668&q=80"
                                        />
                                    </div>
                                    <div class="content">
                                        <div class="details">
                                            <h2>Andrea Styles <br /><span>Senior Web Developer</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

            <section className='Team-Section2'>
                <div class="container">
                    <div className='Team_Section2-Container'>
                        <div className='Team-Section-2-head'>
                            <h2>Alumini ( S2DCian FAN )</h2>
                        </div>
                        <div className='row'>
                            {
                                Alumini.map((ele) => {
                                    const { AluminiName, AluminiImage } = ele

                                    return (
                                        <>
                                            <div className='col-md-3 col-sm-6 col-xs-12 rescard'>
                                                <div class="cards">
                                                    <div class="imgBx">
                                                        <img
                                                            src={AluminiImage}
                                                        />
                                                    </div>
                                                    <div class="content">
                                                        <div class="details">
                                                            <h2>{AluminiName}</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )

                                })
                            }
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Team