import React from 'react';
import "../CSS/AboutUs.css";
import Bounce from 'react-reveal/Bounce';
import NavBar from '../Component/NavBar';
import Zoom from 'react-reveal/Zoom';
import Mission from "../Images/Mission.jpg";
import Team2 from "../Images/Banner 002.webp";
import OurStory from "../Images/Our Story.png";
import Footer from "../Component/Footer";
import CountUp from 'react-countup';

import classImage01 from "../Images/Classes Image 01.jpg";
import classImage02 from "../Images/Classes Image 02.jpg";
import classImage03 from "../Images/Classes Image 03.jpg";
import ServiceData from '../Component/ServiceData';
import { Link } from 'react-router-dom';


const AboutUs = () => {
  return (
    <>
      {/* ///////////////////////////////// section 1 ////////////////////////////////// */}

      <section className='AboutUs-Section-1'>
        <NavBar />
        <div className='AboutUs-Banner'>
          <h1>
            <Bounce top cascade>
              Dance is not just a series of movements, it's a way of life.
            </Bounce>
          </h1>
        </div>
      </section>

      {/* ///////////////////////////////// section 2 ////////////////////////////////// */}

      <section className='Home-Section-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='Home-Section-2-AboutUs-con'>
                <div className='Home-Section-2-AboutUs'>
                  <img src={OurStory} alt="" />
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='Home-Section-2-AboutUs-details-con'>
                <div className='Home-Section-2-AboutUs-details'>
                  <h6>Our Story</h6>

                  <div className='Home-Section-2-AboutUs-details-style'>
                    <h4>S2DC</h4>
                  </div>

                  <p>
                    S2DC offers training classes for those who want to dance for fun, as well as those who want to pursue a professional career in dancing. Each class has a structured syllabus in accordance with the pupil's understanding & level of grasping power. Each class begins with a slow body warm-up and rhythmic movement rehearsal and dance conditioning technique to prepare the body for the specific movements intended in the later part of the class. Specific dance movement techniques are thoroughly broken down and introduced to the students in a simple, easy to understand manner, which is then fused together into combinations and choreographies.
                  </p>
                  <p>
                    Extending the learning beyond the classroom are the annual dance shows and exclusive workshops. The annual dance show performances help the students from different age groups to showcase their art and refine their ability to communicate through dance.
                  </p>
                  <p>
                    Class sizes are typically no more than 10 students. Individual attention and one-on-one teaching are some of the best attributes of S2DC.
                  </p>

                  <div className='Home-Section-2-AboutUs-btn'>
                    <button>
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////////////////////// section 3 ////////////////////////////////// */}

      <section className='About-Section-3'>
        <div className='container'>
          <div className='AboutUs-MIssion-Vission'>

            <div className='Team-Member'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='Team-Member-Image-con'>
                    <div className='Team-Member-Image'>
                      <img src={Mission} alt="" />
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div class="content">
                    <div class="cont text-left">
                      <h4 class="text-left">Mission</h4>
                      <div class="section-text">
                        <p></p>
                        <p>
                          <Zoom cascade>
                            Our mission is to make engagement with dance important to all individuals and communities.
                          </Zoom>
                        </p>
                        <p>
                          <Zoom cascade>
                            You can expect to be dancing the entire duration of the class, get a good workout and learn something new in each and every class.Taking dance training is a great way to meet people  in your community, strengthen and empower your mind and body, and build confidence.
                          </Zoom>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className='Team-Member'>
                <div className='row'>
                  <div className='col-md-6 order-md-1 order-2'>
                    <div class="content">
                      <div class="cont text-left">
                        <h4 class="text-left">Vission</h4>
                        <div class="section-text">
                          <p></p>
                          <p>
                            <Zoom cascade>
                              At Subhash Shukla Dance Company (S2DC) it is our vision to see a world where dance is a part of everyone’s life, regardless of age, ability or disability & to give them a stress free life.                            </Zoom>
                          </p>
                          {/* <p>
                            <Zoom cascade>
                              Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus esse sint, mollitia obcaecati recusandae est eos pariatur sed vel, dignissimos cumque laudantium. Tempore praesentium numquam exercitationem excepturi esse architecto obcaecati?
                            </Zoom>
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6 order-md-2 order-1'>
                    <div className='Team-Member-Image-con'>
                      <div className='Team-Member-Image'>
                        <img src={Team2} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ///////////////////////////////// section 4 ////////////////////////////////// */}
      <section>
        <div className='section-Aboutus-4'>
          <div className='container'>
            <div className="section1-Aboutus-card-con">

              <div className='row'>

                {
                  ServiceData.map((ele) => {
                    const { Slug, ServiceImage, ServiceName } = ele
                    return (
                      <>
                        <div className='col-md-4'>
                          <Link to={`${Slug}`}>
                            <div className='AS4-Classes-Card-body'>
                              <div className='AS4-Classes-Card-Image'>
                                <img src={ServiceImage} alt="" />
                              </div>

                              <div className='AS4-Classes-Card-text'>
                                <h4>{ServiceName}</h4>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>




      {/* ///////////////////////// Section 5 ///////////////////// */}


      <section className='AboutUs-Section-5'>
        <div className='container'>
          {/* <div className='AboutUs-Section-5-Heading'>
            <h2>We are happy for our achievement</h2>
          </div> */}
          <div className='row'>
            <div className='col-md-3 col-sm-3'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={4} end={10000} >

                  </CountUp>
                </h2>
                <p>SHOWES</p>
              </div>
            </div>
            <div className='col-md-3 col-sm-3'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={4} end={10000} >

                  </CountUp>
                </h2>
                <p>STUDENTS</p>
              </div>
            </div>
            <div className='col-md-3 col-sm-3'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={4} end={1000} >

                  </CountUp>
                </h2>
                <p>DANCER</p>
              </div>
            </div>
            <div className='col-md-3 col-sm-3'>
              <div className='Counter-Container'>
                <h2>
                  <CountUp delay={4} end={10} >

                  </CountUp>
                </h2>
                <p>TEACHERS</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default AboutUs