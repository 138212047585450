import React from 'react';
import { Link } from "react-router-dom";
import "../CSS/Footer.css"
import Logo from "../Images/D2DC_Logo-removebg-preview.png";
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { RiWhatsappLine, RiFacebookFill, RiInstagramFill } from 'react-icons/ri';

const Footer = () => {
    return (
        <>
            <section className='Footer-Section'>
                <div className='container'>
                    <div className='Footer-con'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <div className='Footer-Logo-con'>
                                    <div className='Footer-Image'>
                                        <img src={Logo} alt="" />
                                    </div>
                                    <p>
                                        Dance classes offer a diverse range of styles and fitness benefits, catering to all ages and skill levels. Participants learn techniques, practice routines, and enjoy a supportive community, fostering confidence and artistic expression.
                                    </p>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='footer-Mid-Col'>
                                    <div className='nav_menu-2'>
                                        <h3 className='footer-title'>
                                            Explore
                                        </h3>
                                        <div className='menu-footer-menu-container'>
                                            <ul className='menu-footer-menu'>
                                                <li className='menu-item'>
                                                    <Link to='/'>
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/Service'>
                                                        Service
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/Gallery'>
                                                        Gallery
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/AboutUs'>
                                                        About
                                                    </Link>
                                                </li>
                                                <li className='menu-item'>
                                                    <Link to='/ContactUs'>
                                                        Contact
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='footer-column'>
                                    <div className='widget'>
                                        <h3>Get in touch</h3>
                                        <div className='footer-contact'>
                                            <p className='footer-contact-text'>
                                                <a href='https://www.google.com/maps/place/Whatever+events/@19.134346,72.82646,11z/data=!4m6!3m5!1s0x3be7b7ac1ba921cb:0xc852cf985b354903!8m2!3d19.1343458!4d72.82646!16s%2Fg%2F11sd6_6w3h?hl=en' target='blank'>
                                                    Office No 411, MOHID HEIGHTS, opposite Mhada Road, near kokilaben hospital signal, Suresh Nagar,<br></br>
                                                    Andheri West, Mumbai, Maharashtra 400053
                                                </a>
                                            </p>
                                            <div className='footer-contact-info'>
                                                <p className='footer-contact-phone'>
                                                    <span className='ti-headphone-alt'>
                                                        <TfiHeadphoneAlt />
                                                    </span>
                                                    <a href="tel:902948041">+91 90294 80415</a>
                                                </p>
                                                <p className='footer-contact-mail'>
                                                    <a href="mailto: whateverevents63@gmail.com">
                                                        Demo@gmail.com
                                                    </a>
                                                </p>
                                            </div>
                                            <div className='Footer-Social-Media'>
                                                <a href='' target="blank"><RiWhatsappLine /></a>
                                                <a href='' target="blank"><RiFacebookFill /></a>
                                                <a href='' target="blank"><RiInstagramFill /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='Copy-Right'>
                        <div className='Contact-Us-Copy'>
                            © Copyright
                            <strong>
                                <span style={{ color: "#d6a063" }}> S2DC </span>
                            </strong>
                            . All Rights Reserved
                        </div>
                        <div className="credits">
                            Designed by
                            <a href="https://skdm.in/" style={{ color: "#d6a063" }}>
                                Shree Krishna Digital
                            </a>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Footer