import React, { useState } from 'react';
import "../CSS/NavBar.css";
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import { AiFillInstagram, AiOutlineTwitter, AiFillYoutube } from 'react-icons/ai';
import { RiWhatsappFill } from 'react-icons/ri';
import { FaFacebookSquare } from 'react-icons/fa';
import { IoLogoLinkedin } from 'react-icons/io';
// import Enquiry from "../Pages/Enquiry";
import Logo from "../Images/D2DC_Logo-removebg-preview.png";


const NavBar = () => {

    const [modalShow, setModalShow] = React.useState(false)

    const [color, setColor] = useState(false)

    const changeColor = () => {
        if (window.scrollY >= 78) {
            setColor(true)
        } else {
            setColor(false)
        }
    }

    window.addEventListener('scroll', changeColor)


    return (

        <>
            <div className="nav-bar-component">
                <section className="NavSection">
                    {/* <div className="container"> */}
                    <Navbar collapseOnSelect expand="lg" variant="dark" className={color ? "NavBar NavBar-bg" : "NavBar"}>
                        {/* <Container> */}
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Brand href="#home">
                            <div className='NavLogo'>
                                <img src={Logo} alt="" />
                            </div>
                        </Navbar.Brand>
                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
                            <Nav>
                                <Nav.Link href="#home"> <Link to="/">Home</Link> </Nav.Link>
                                <Nav.Link href="#About us"><Link to="/AboutUs">About</Link></Nav.Link>
                                <Nav.Link href="#Services"><Link to="/TeamS2DC">Team S2DC</Link></Nav.Link>
                                <Nav.Link href="#Gallery"><Link to="/Gallery">Gallery</Link></Nav.Link>
                                <Nav.Link href="#About us"><Link to="/Testimonial">Testimonial</Link></Nav.Link>
                                <Nav.Link href="#Services"><Link to="/Service">Service</Link></Nav.Link>
                                <Nav.Link href="#Contact us"><Link to="/ContactUs">Contact</Link></Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                        <Nav className="Social-media-icons">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    Social Media
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1"><Nav.Link href='#' target="_blank"><FaFacebookSquare /></Nav.Link></Dropdown.Item>
                                    <Dropdown.Item href="#/action-2"><Nav.Link href='#' target="_blank"><AiFillInstagram /></Nav.Link></Dropdown.Item>
                                    <Dropdown.Item href="#/action-2"><Nav.Link href='#' target="_blank"><AiOutlineTwitter /></Nav.Link></Dropdown.Item>
                                    <Dropdown.Item href="#/action-2"><Nav.Link href='#' target="_blank"><AiFillYoutube /></Nav.Link></Dropdown.Item>
                                    <Dropdown.Item href="#/action-2"><Nav.Link href='#' target="_blank"><IoLogoLinkedin /></Nav.Link></Dropdown.Item>
                                    <Dropdown.Item href="#/action-3"><Nav.Link href='#' target="_blank"><RiWhatsappFill /></Nav.Link></Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* <Nav.Link href='https://www.facebook.com/profile.php?id=100090666117997' target="_blank"><FaFacebookSquare /></Nav.Link>
                            <Nav.Link href='https://www.instagram.com/_unico_india/' target="_blank"><AiFillInstagram /></Nav.Link>
                            <Nav.Link href='https://wa.me/9320428428?text=' target="_blank"><RiWhatsappFill /></Nav.Link> */}
                        </Nav>
                        {/* </Container> */}
                    </Navbar>
                    {/* </div> */}
                </section>
                {/* <Enquiry
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                /> */}
            </div>
        </>

    )
}

export default NavBar